/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/**custom badges*/
.approve-badge {
	background: rgba(40, 199, 111, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	justify-content: center !important;
	align-items: center !important;
	color: #28c76f !important;
}

.suspended-badge {
	background: rgba(108, 117, 125, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #82868b;
	justify-content: center !important;
	align-items: center !important;
}

.suspended-badge-2 {
	background: #FEF3F3 !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #EB1608;;
	justify-content: center !important;
	align-items: center !important;
}

.pending-badge {
	background: rgba(255, 159, 67, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #ff9f43;
	justify-content: center !important;
	align-items: center !important;
}

.saved-badge {
	background: rgba(97, 177, 218, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #60b8e4;
	justify-content: center !important;
	align-items: center !important;
}

.badge-normal {
	background: #F0F0F0 !important;
	border-radius: 17px;
	padding: 4px 16px;
	justify-content: center !important;
	align-items: center !important;
	color: #A9A9A9 !important;
}

.badge-sendback {
	background: #FFEBF1 !important;
	border-radius: 17px;
	padding: 4px 16px;
	justify-content: center !important;
	align-items: center !important;
	color: #EB084C !important;
}

.card-main-title {
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #4b4948;
}

.form-label {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #4b4948;
}

.rdt_Table {
	min-height: 400px;
}

.sent-badge {
	background: #eaf0f7 !important;
	border-radius: 17px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.4px;
	color: #2b68ad;
}

.invoice-status {
	// background: #eaf0f7 !important;
	// border-radius: 17px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 16px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.4px;
	// color: #2b68ad;
}

.keyword-search {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
	color: #c0c0c7;
}

.sent-status {
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	color: #747d88;
}

.blue-badge {
	background: #2b68ad !important;
	border-radius: 17px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.4px;
	color: #fff;
}

.time-format {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: #c0c0c7;
}

.green-status {
	background: rgba(40, 199, 111, 0.12);
	border-radius: 17px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	color: #28c76f;
	font-size: 12px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.4px;
}

.bdr-hr {
	border: 1px solid rgba(66, 176, 238, 0.1);
}

.graph-dropdown {
	max-height: 200px;
	overflow: auto;
}

.image-label-wrapper {
	border: none;
	background-color: #ffff !important;
}

.invoice-bg {
	background: rgba(43, 104, 173, 0.05) !important;
}

.invoice-item-bg {
	background: #fff !important;
}

.invoice-burden-text {
	background: #f8f8f8;
	border-radius: 6px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	color: #4b4948;
}

.invoice-tex1 {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	text-align: right;
	letter-spacing: 0.4px;
	color: #4b4948;
}

.invoice-heading1 {
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	color: #4b4948;
}

.invoice-main-heading {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 35px;
	text-align: center;
	color: #4b4948;
}

.annoncement-cirle {
	border-radius: 18px;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	width: 24px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.all-emp-bg {
	background: rgba(115, 103, 240, 0.12);
	color: #2b68ad;
}

.cmp-user-bg {
	color: #ff9f43;
	background: rgba(255, 159, 67, 0.12);
}

.restaurant-bg {
	background: rgba(234, 84, 85, 0.12);
	color: #ea5455;
}

.badge-type1 {
	border-radius: 17px !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	font-weight: 400;
}

.pending-approval-badge {
	background: rgba(43, 104, 173, 0.1) !important;
	color: #2b68ad !important;
}

.pending-approval-reject {
	background: rgba(108, 117, 125, 0.12) !important;
	color: #82868b !important;
}

.graph-dropdown {
	background: #ffffff;
	border: 0.8px solid #c0c0c7 !important;
	box-sizing: border-box;
	box-shadow: 0px 4px 15px rgba(116, 125, 136, 0.1);
	border-radius: 7px;
	// width: 180px !important;
	color: #4b4948 !important;
}

.graph-dropdown-btn {
	width: 180px !important;
}

.graph-card {
	margin-bottom: -10px !important;
}

.graph-card-header {
	margin-bottom: -26px !important;
}

.btn-primary {
	background: linear-gradient(87.43deg, #2b68ad 19.76%, #78a3d5 126.74%) !important;
	box-shadow: 0px 4px 45px rgba(43, 104, 173, 0.25) !important;
}

.f12 {
	font-size: 12px;
}

.f16 {
	font-size: 16px;
}

.tilt {
	color: #4B4948;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
}

.mt8 {
	margin-top: 8px;
}

/*Table main title*/
.page-menu-title {
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	color: #4B4948;
}

.bhr{
	border-right: 1px solid #D1D4D4;
	margin-left:15px;
	margin-right:5px;
}

.f12{
	font-size: 12px;
}